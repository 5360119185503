import { Button, Space, Table, TablePaginationConfig } from 'antd';
import { useForm } from 'antd/es/form/Form';
import useModal from 'antd/es/modal/useModal';
import { ColumnType } from 'antd/es/table';
import { DriversForm } from './drivers-form.component';
import { LeaveForm } from './leave-form.component';

type Props = {
  isLoading: boolean;
  driversInfo: {
    id: string;
    idNumber: string;
    name: string;
    address: string;
    phone: string;
    carNumber: string;
    carModel: string;
    carColor: string;
    bankCode?: string;
    bankAccount?: string;
    bankName?: string;
  }[];
  pagination: TablePaginationConfig;
  createDriver: (values: {
    name: string;
    address: string;
    phone: string;
    carNumber: string;
    carModel: string;
    carColor: string;
    bankCode: string | null;
    bankAccount: string | null;
    bankName: string | null;
  }) => Promise<any>;
  updateDriver: (values: {
    id: string;
    name: string;
    address: string;
    phone: string;
    carNumber: string;
    carModel: string;
    carColor: string;
    bankCode: string | null;
    bankAccount: string | null;
    bankName: string | null;
  }) => Promise<any>;
  deleteDriver: (id: string) => Promise<any>;
  upateDriverLeave: (
    data: [
      {
        time: number;
        driverId: string;
        isLeave: Boolean;
      },
    ]
  ) => Promise<any>;
};

export function DriversComponent(props: Props) {
  const [form] = useForm<{
    idNumber: string;
    name: string;
    address: string;
    phone: string;
    carNumber: string;
    carModel: string;
    carColor: string;
    bankCode?: string;
    bankAccount?: string;
    bankName?: string;
  }>();
  const [leaveForm] = useForm<{
    id: string;
    idNumber: string;
    time: [number];
  }>();
  const [modal, contextHolder] = useModal();

  const onCreateButtonClick = () => {
    modal.confirm({
      cancelText: '取消',
      content: <DriversForm form={form} />,
      icon: null,
      okText: '確認',
      title: '新增司機',
      onOk: async () => {
        const values = await form.validateFields();

        return props.createDriver({
          ...values,
          bankCode: values.bankCode || null,
          bankAccount: values.bankAccount || null,
          bankName: values.bankName || null,
        });
      },
    });
  };

  const onEditButtonClick = (data: Props['driversInfo'][number]) => {
    modal.confirm({
      cancelText: '取消',
      content: (
        <DriversForm
          form={form}
          data={{
            ...data,
            bankCode: data.bankCode,
            bankAccount: data.bankAccount,
            bankName: data.bankName,
          }}
        />
      ),
      icon: null,
      okText: '確認',
      title: '編輯司機',
      onOk: async () => {
        const values = await form.validateFields();

        return props.updateDriver({
          id: data.id,
          ...values,
          bankCode: values.bankCode || null,
          bankAccount: values.bankAccount || null,
          bankName: values.bankName || null,
        });
      },
    });
  };

  const onLeaveButtonClick = (data: Props['driversInfo'][number]) => {
    modal.confirm({
      cancelText: '取消',
      content: <LeaveForm form={leaveForm} data={data} />,
      icon: null,
      okText: '確認',
      title: '司機請假',
      onOk: () => {
        const updateData = leaveForm.getFieldValue('time');

        if (updateData.length) {
          return props.upateDriverLeave(updateData);
        }
      },
    });
  };

  const onDeleteButtonClick = (id: string) => {
    modal.confirm({
      cancelText: '取消',
      content: '確定要刪除嗎？',
      icon: null,
      okText: '確認',
      title: '刪除司機',
      onOk: async () => {
        return props.deleteDriver(id);
      },
    });
  };

  const columns: ColumnType<Props['driversInfo'][number]>[] = [
    {
      title: '代號',
      dataIndex: 'idNumber',
    },
    {
      title: '名字',
      dataIndex: 'name',
    },
    {
      title: '地址',
      dataIndex: 'address',
    },
    {
      title: '電話',
      dataIndex: 'phone',
    },
    {
      title: '車牌',
      dataIndex: 'carNumber',
    },
    {
      title: '車型',
      dataIndex: 'carModel',
    },
    {
      title: '車色',
      dataIndex: 'carColor',
    },
    {
      title: '操作',
      width: 100,
      render: (record) => (
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              onEditButtonClick(record);
            }}
          >
            編輯
          </Button>
          <Button
            size="small"
            onClick={() => {
              onLeaveButtonClick(record);
            }}
          >
            請假
          </Button>
          <Button
            danger
            size="small"
            onClick={() => {
              onDeleteButtonClick(record.id);
            }}
          >
            刪除
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Space className="w-full" direction="vertical">
      {contextHolder}
      <div className="text-2xl font-bold">司機列表</div>
      <Table
        columns={columns}
        loading={props.isLoading}
        pagination={props.pagination}
        scroll={{ x: 'max-content' }}
        size="small"
        rowKey="id"
        title={() => (
          <Button type="primary" onClick={onCreateButtonClick}>
            建立司機
          </Button>
        )}
        dataSource={props.driversInfo || []}
      ></Table>
    </Space>
  );
}
