import { DatePicker, Select } from 'antd';
import Table, { ColumnType } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import locale from 'antd/es/date-picker/locale/zh_TW';
import { forEach } from 'lodash';

type OrderState = Array<
  '已確認' | '未確認' | '取消訂單' | '取消訂單-退貨' | '結案'
>;

const ORDER_STATE_OPTIONS = [
  { label: '已確認', value: '已確認' },
  { label: '未確認', value: '未確認' },
  { label: '取消訂單', value: '取消訂單' },
  { label: '取消訂單-退貨', value: '取消訂單-退貨' },
  { label: '結案', value: '結案' },
];

type OrderStatisticsComponentProps = {
  locationCounts: Array<{
    id: string;
    value: number;
    idNumber: number;
  }>;
  providerCounts: Array<{
    id: string;
    value: number;
  }>;
  isLoading: boolean;
  onDateChange: (dates: any, dateStrings: string[]) => void;
  onOrderStateChange: (value: OrderState) => void;
  redeemDate: [Dayjs, Dayjs];
  orderState: OrderState;
};

export function OrderStatisticsComponent(props: OrderStatisticsComponentProps) {
  const locationColumns: ColumnType<
    OrderStatisticsComponentProps['locationCounts'][number]
  >[] = [
    {
      align: 'center',
      dataIndex: 'idNumber',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.idNumber - b.idNumber,
      title: '編號',
      width: 60,
    },
    {
      title: '地點',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '數量',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const providerColumns: ColumnType<
    OrderStatisticsComponentProps['providerCounts'][number]
  >[] = [
    {
      title: '供應商',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '數量',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  return (
    <div className="flex flex-col gap-y-4">
      <div className="text-2xl font-bold">訂單統計</div>
      <div className="flex items-center gap-x-2">
        <span>篩選取貨日期</span>
        <DatePicker.RangePicker
          allowClear={false}
          locale={locale}
          onChange={props.onDateChange}
          value={props.redeemDate}
          presets={[
            {
              label: '今天',
              value: [dayjs().startOf('date'), dayjs().endOf('date')],
            },
            {
              label: '昨天',
              value: [
                dayjs().startOf('date').subtract(1, 'day'),
                dayjs().endOf('date').subtract(1, 'day'),
              ],
            },
            {
              label: '本週',
              value: [dayjs().startOf('week'), dayjs().endOf('week')],
            },
            {
              label: '上週',
              value: [
                dayjs().startOf('week').subtract(1, 'week'),
                dayjs().endOf('week').subtract(1, 'week'),
              ],
            },
            {
              label: '本月',
              value: [dayjs().startOf('month'), dayjs().endOf('month')],
            },
            {
              label: '前月',
              value: [
                dayjs().startOf('month').subtract(1, 'month'),
                dayjs().endOf('month').subtract(1, 'month'),
              ],
            },
          ]}
        />
      </div>
      <div className="flex items-center gap-x-2">
        <span>篩選訂單狀態</span>
        <Select
          mode="multiple"
          allowClear
          style={{ minWidth: 200 }}
          placeholder="請選擇訂單狀態"
          value={props.orderState}
          onChange={props.onOrderStateChange}
          options={ORDER_STATE_OPTIONS}
        />
      </div>
      <div className="flex gap-4 flex-grow shrink [&>*]:flex-grow [&>*]:basis-1/2">
        <Table
          rowKey="id"
          size="small"
          dataSource={props.locationCounts}
          columns={locationColumns}
          pagination={false}
          title={() => <span className="text-lg font-bold">大樓總數</span>}
          loading={props.isLoading}
          summary={(pageData) => {
            let total = 0;

            forEach(pageData, ({ value }) => {
              total += value;
            });

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>總數</Table.Summary.Cell>
                  <Table.Summary.Cell index={1} />
                  <Table.Summary.Cell index={2}>{total}</Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        <Table
          rowKey="id"
          size="small"
          dataSource={props.providerCounts}
          columns={providerColumns}
          pagination={false}
          title={() => <span className="text-lg font-bold">餐廳總數</span>}
          loading={props.isLoading}
          summary={(pageData) => {
            let total = 0;

            forEach(pageData, ({ value }) => {
              total += value;
            });

            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>總數</Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>{total}</Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </div>
    </div>
  );
}
