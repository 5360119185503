import {
  Button,
  Col,
  DatePicker,
  Radio,
  Row,
  Space,
  Table,
  TablePaginationConfig,
} from 'antd';
import locale from 'antd/es/date-picker/locale/zh_TW';
import { useForm } from 'antd/es/form/Form';
import useModal from 'antd/es/modal/useModal';
import { ColumnType } from 'antd/es/table';
import { DriversForm } from './drivers-event-form.component';
import { map } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import {
  EXPORT_PERIOD,
  EXPORT_PERIOD_OPTIONS,
} from '../../constants/statement-form';

const WEEK_TRANSLATE = ['日', '一', '二', '三', '四', '五', '六'];

type Props = {
  isLoading: boolean;
  isExportLoading: boolean;
  driversSalary: {
    driver: {
      id: string;
      idNumber: string;
      name: string;
    };
    deliveryDetails: {
      adjustedSalary: number;
      events: {
        id: string;
        amount: number;
        note: string;
      }[];
      locationTags: [];
      salary: number;
      time: number;
    }[];
  }[];
  pagination: TablePaginationConfig;
  createDriverEvent: (values: {
    driverId: string;
    amount: number;
    note: string;
    time: number;
  }) => Promise<any>;
  deleteDriverEvent: (id: string) => Promise<any>;
  timeRange: {
    startTime: Dayjs;
    endTime: Dayjs;
  };
  onChangeTimeRange: (data: { startTime: Dayjs; endTime: Dayjs }) => void;
  exportDriverSalary: () => void;
};

export function DriversSalaryComponent(props: Props) {
  const [form] = useForm<{
    driverId: string;
    amount: number;
    note: string;
    time: number;
  }>();
  const [modal, contextHolder] = useModal();

  const onCreateButtonClick = () => {
    const driverOptions = map(props.driversSalary, (item) => ({
      label: `${item.driver.idNumber} - ${item.driver.name}`,
      value: item.driver.id,
    }));

    modal.confirm({
      cancelText: '取消',
      content: <DriversForm form={form} driverOptions={driverOptions} />,
      icon: null,
      okText: '確認',
      title: '新增司機事件',
      onOk: async () => {
        const values = await form.validateFields();

        return props.createDriverEvent({
          ...values,
          time: dayjs(values.time).startOf('date').valueOf(),
        });
      },
    });
  };

  const onViewButtonClick = (
    data: Props['driversSalary'][number]['deliveryDetails'],
    title = ''
  ) => {
    const modalInstance = modal.info({
      width: 1024,
      content: (
        <div>
          <Row
            className="w-full p-1"
            style={{ borderTop: '1px solid #ccc', fontWeight: 700 }}
          >
            <Col span={4}>日期</Col>
            <Col span={6}>配送路線</Col>
            <Col span={3}>原始薪資</Col>
            <Col span={3}>實發薪資</Col>
            <Col span={8}>事件備註</Col>
          </Row>
          {map(data, (dayRecord) => (
            <Row
              key={dayRecord.time}
              className="w-full p-1"
              style={{ borderTop: '1px solid #ccc' }}
            >
              <Col span={4}>
                {dayjs(dayRecord.time).format('YYYY-MM-DD')} (
                {WEEK_TRANSLATE[dayjs(dayRecord.time).get('day')]})
              </Col>
              <Col span={6}>
                <ul className="m-0 pl-5">
                  {map(
                    dayRecord.locationTags,
                    (item: { id: string; name: string }) => (
                      <li key={item.id}>{item.name}</li>
                    )
                  )}
                </ul>
              </Col>
              <Col span={3}>
                {dayRecord.salary.toLocaleString('zh-TW', {
                  style: 'currency',
                  currency: 'TWD',
                  minimumFractionDigits: 0,
                })}
              </Col>
              <Col span={3}>
                {dayRecord.adjustedSalary.toLocaleString('zh-TW', {
                  style: 'currency',
                  currency: 'TWD',
                  minimumFractionDigits: 0,
                })}
              </Col>
              <Col span={8}>
                {map(dayRecord.events, (event) => (
                  <div key={event.id}>
                    <Button
                      className="mr-1"
                      size="small"
                      danger
                      type="default"
                      onClick={async () => {
                        await props.deleteDriverEvent(event.id);
                        modalInstance.destroy();
                      }}
                    >
                      刪除
                    </Button>
                    {event.amount.toLocaleString('zh-TW', {
                      style: 'currency',
                      currency: 'TWD',
                      minimumFractionDigits: 0,
                    })}
                    （{event.note}）
                  </div>
                ))}
              </Col>
            </Row>
          ))}
        </div>
      ),
      icon: null,
      okText: '關閉',
      title,
    });
  };

  const columns: ColumnType<Props['driversSalary'][number]>[] = [
    {
      title: '代號',
      dataIndex: ['driver', 'idNumber'],
    },
    {
      title: '名字',
      dataIndex: ['driver', 'name'],
    },
    {
      title: '原始薪資',
      dataIndex: 'totalSalary',
      render: (value) =>
        value.toLocaleString('zh-TW', {
          style: 'currency',
          currency: 'TWD',
          minimumFractionDigits: 0,
        }),
    },
    {
      title: '實發薪資',
      dataIndex: 'totalAdjustedSalary',
      render: (value) =>
        value.toLocaleString('zh-TW', {
          style: 'currency',
          currency: 'TWD',
          minimumFractionDigits: 0,
        }),
    },
    {
      title: '操作',
      width: 100,
      render: (_, record) => (
        <Space>
          <Button
            size="small"
            onClick={() => {
              onViewButtonClick(
                record.deliveryDetails,
                `${record.driver.idNumber} - ${record.driver.name}`
              );
            }}
          >
            薪資明細
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <Space className="w-full" direction="vertical">
      {contextHolder}

      <div className="text-2xl font-bold">司機薪資</div>
      <Table
        columns={columns}
        loading={props.isLoading}
        pagination={props.pagination}
        scroll={{ x: 'max-content' }}
        size="small"
        rowKey={(row) => row.driver.id}
        title={() => (
          <div className="flex flex-col items-start gap-2">
            <Space>
              <Button type="primary" onClick={onCreateButtonClick}>
                新增司機事件
              </Button>
              <Button
                type="primary"
                onClick={props.exportDriverSalary}
                loading={props.isExportLoading}
              >
                匯出司機薪資
              </Button>
            </Space>

            <Space>
              <DatePicker
                picker="month"
                locale={locale}
                value={props.timeRange?.startTime || dayjs()}
                format="YYYY-MM"
                onChange={(dates: Dayjs | null) => {
                  props.onChangeTimeRange({
                    startTime: (dates || dayjs()).startOf('month'),
                    endTime: (dates || dayjs()).startOf('month').add(14, 'day'),
                  });
                }}
              />
              <Radio.Group
                value={
                  props.timeRange.startTime.get('date') <= 15
                    ? EXPORT_PERIOD.FIRST_HALF
                    : EXPORT_PERIOD.LATTER_HALF
                }
                onChange={(e) => {
                  const startTime = dayjs(
                    props.timeRange.startTime
                      .startOf('month')
                      .set(
                        'date',
                        e.target.value === EXPORT_PERIOD.FIRST_HALF ? 1 : 16
                      )
                  );
                  props.onChangeTimeRange({
                    startTime,
                    endTime: startTime.add(14, 'day'),
                  });
                }}
                options={EXPORT_PERIOD_OPTIONS}
              />
            </Space>
          </div>
        )}
        dataSource={props.driversSalary || []}
      ></Table>
    </Space>
  );
}
