import { DatePicker, Form, Input, InputNumber, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_TW';
import { FormInstance } from 'antd/es/form/Form';
import { useEffect } from 'react';

export function DriversForm(props: {
  form: FormInstance;
  data?: {
    driver: {
      id: string;
      idNumber: string;
      name: string;
    };
  };
  driverOptions: { label: string; value: string }[];
}) {
  useEffect(() => {
    props.form.resetFields();
  }, [props.form]);

  return (
    <Form form={props.form} autoComplete="off" initialValues={props.data}>
      <Form.Item
        label="時間"
        name="time"
        rules={[{ required: true, message: '請選擇時間' }]}
      >
        <DatePicker locale={locale} />
      </Form.Item>
      <Form.Item
        label="司機"
        name="driverId"
        rules={[{ required: true, message: '請選擇司機' }]}
      >
        <Select placeholder="請選擇司機" options={props.driverOptions} />
      </Form.Item>
      <Form.Item
        label="調整金額"
        name="amount"
        rules={[{ required: true, message: '請輸入調整金額' }]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item
        label="備註"
        name="note"
        rules={[{ required: true, message: '請輸入備註' }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}
