import { Button, Dropdown, Table, TablePaginationConfig } from 'antd';
import useModal from 'antd/es/modal/useModal';
import { StatementExportFormComponent } from './statement-export-form.component';
import { useForm } from 'antd/es/form/Form';
import {
  EXPORT_PERIOD,
  STATEMENT_EXPORT_TYPE,
} from '../../constants/statement-form';
import dayjs, { Dayjs } from 'dayjs';
import { IgnoredToppingsFormComponent } from './ignored-toppings-form.component';
import { ColumnType } from 'antd/es/table';
import { StatementFormComponent } from './statement-form.component';
import {
  MoreOutlined,
  DeleteOutlined,
  EditOutlined,
  TransactionOutlined,
} from '@ant-design/icons';
import {
  STATEMENT_STATE,
  STATEMENT_STATE_FILTERS,
  STATEMENT_STATE_LABEL,
} from '../../constants/statement-state';
import { every, includes, keyBy, map } from 'lodash';
import { Key, useState } from 'react';
import { CancelStatementFormComponent } from './cancel-statement-form.component';
import {
  ExportStatementsDto,
  ResolveStatementDto,
} from '../../utils/api-client';

type Props = {
  cancelStatement: (data: { id: string; note: string | null }) => Promise<void>;
  ignoredToppings: string[];
  isLoading: boolean;
  pagination: TablePaginationConfig;
  payStatements: (ids: Array<Key>) => Promise<any>;
  sendStatementConfirmNotification: (ids: Array<Key>) => Promise<any>;
  setFilter: (filter: Record<string, any>) => void;
  resolveStatement: (
    data: ResolveStatementDto & { id: string }
  ) => Promise<any>;
  timeFilters: Array<Array<string>>;
  updateIgnoredToppings: (data: { toppings: Array<string> }) => Promise<void>;
  updateStatement: (data: { id: string; note: string | null }) => Promise<void>;
  downloadStatementDetail: (ids: Array<Key>) => void;
  exportProviderStatements: (data: ExportStatementsDto) => Promise<any>;
  statements: Array<{
    amount: number;
    createdAt: string;
    detailUrl: string;
    endTime: string;
    id: string;
    note: string;
    startTime: string;
    state: STATEMENT_STATE;
    statementUrl: string;
    provider: {
      id: string;
      name: string;
      idNumber: string;
    };
    stateLog: Array<{
      state: STATEMENT_STATE;
      time: string;
      note: string | null;
    }>;
    itemAmount: number;
    toppingAmount: number;
    refundAmount: number;
    commissionAmount: number;
    fineAmount: number;
  }>;
  providerFilters: Array<{
    value: string;
    text: string;
  }>;
};

export function StatementsComponent(props: Props) {
  const [modal, contentHolder] = useModal();
  const [statementForm] = useForm<{
    note: string | null;
  }>();
  const [form] = useForm<{
    month: Dayjs;
    period: EXPORT_PERIOD;
    providerIds: Array<string> | undefined;
    exportType: STATEMENT_EXPORT_TYPE;
    dateRange: [Dayjs, Dayjs] | undefined;
    remittanceDate: Dayjs | undefined;
    invoiceReceivedDueDate: Dayjs | undefined;
  }>();
  const [toppingsForm] = useForm<{
    toppings: Array<string>;
  }>();
  const [cancelForm] = useForm<{
    note: string | null;
  }>();
  const [selectedIds, setSelectedIds] = useState<Key[]>([]);

  const onDownloadStatementDetailButtonClick = () => {
    props.downloadStatementDetail(selectedIds);
  };

  const onPayButtonClick = () => {
    const statementsDict = keyBy(props.statements, 'id');
    const selectedStatements = map(
      selectedIds,
      (id) => statementsDict[String(id)]
    );

    if (
      !every(
        selectedStatements,
        (statement) => statement.state === STATEMENT_STATE.PENDING_PAYMENT
      )
    ) {
      modal.warning({
        content: '請確認選取的對帳單狀態都是待匯款',
        okText: '確定',
      });

      return;
    }

    modal.confirm({
      cancelText: '取消',
      width: 640,
      icon: null,
      okText: '確定',
      title: '變更對帳單狀態為已付款',
      content: (
        <Table dataSource={selectedStatements} pagination={false} rowKey="id">
          <Table.Column
            title="對帳單日期"
            width={200}
            render={(statement) =>
              `${new Date(
                statement.startTime
              ).toLocaleDateString()} ~ ${new Date(
                statement.endTime
              ).toLocaleDateString()}`
            }
          />
          <Table.Column
            dataIndex="provider"
            title="供應商"
            render={(provider) => `${provider.idNumber} - ${provider.name}`}
          />
          <Table.Column
            dataIndex="amount"
            title="金額"
            render={(amount) =>
              amount.toLocaleString('zh-TW', {
                style: 'currency',
                currency: 'TWD',
                minimumFractionDigits: 0,
              })
            }
          />
        </Table>
      ),
      onOk: () => {
        return props.payStatements(selectedIds).then(() => {
          setSelectedIds([]);
        });
      },
    });
  };

  const onSendStatementConfirmNotificationButtonClick = () => {
    const statementsDict = keyBy(props.statements, 'id');
    const selectedStatements = map(
      selectedIds,
      (id) => statementsDict[String(id)]
    );

    if (
      !every(
        selectedStatements,
        (statement) => statement.state === STATEMENT_STATE.CREATED
      )
    ) {
      modal.warning({
        content: '請確認選取的對帳單狀態都是已建立',
        okText: '確定',
      });

      return;
    }

    modal.confirm({
      cancelText: '取消',
      content: (
        <Table dataSource={selectedStatements} pagination={false} rowKey="id">
          <Table.Column
            title="對帳單日期"
            width={200}
            render={(statement) =>
              `${new Date(
                statement.startTime
              ).toLocaleDateString()} ~ ${new Date(
                statement.endTime
              ).toLocaleDateString()}`
            }
          />
          <Table.Column
            dataIndex="provider"
            title="供應商"
            render={(provider) => `${provider.idNumber} - ${provider.name}`}
          />
          <Table.Column
            dataIndex="amount"
            title="金額"
            render={(amount) =>
              amount.toLocaleString('zh-TW', {
                style: 'currency',
                currency: 'TWD',
                minimumFractionDigits: 0,
              })
            }
          />
        </Table>
      ),
      icon: null,
      okText: '確定',
      width: 640,
      title: '發送對帳單確認通知',
      onOk: () => {
        return props.sendStatementConfirmNotification(selectedIds).then(() => {
          setSelectedIds([]);
        });
      },
    });
  };

  const onUpdateButtonClick = (statement: Props['statements'][number]) => {
    modal.confirm({
      icon: null,
      title: '編輯對帳單',
      content: (
        <StatementFormComponent form={statementForm} statement={statement} />
      ),
      okText: '確定',
      cancelText: '取消',
      onOk: () => {
        const values = statementForm.getFieldsValue();

        return props.updateStatement({ id: statement.id, note: values.note });
      },
    });
  };

  const onExportButtonClick = () => {
    form.setFieldsValue({
      month: dayjs(),
      period: EXPORT_PERIOD.FIRST_HALF,
      providerIds: undefined,
      exportType: STATEMENT_EXPORT_TYPE.BIWEEKLY,
    });

    modal.confirm({
      icon: null,
      title: '匯出對帳單',
      content: <StatementExportFormComponent form={form} />,
      okText: '匯出',
      cancelText: '取消',
      onOk: () => {
        const values = form.getFieldsValue();

        const { dateRange: [startDate, endDate] = [] } = values;

        return props.exportProviderStatements({
          endDate: endDate?.toDate().toISOString(),
          exportType: values.exportType,
          month: values.month?.toDate().toISOString(),
          period: values.period,
          providerIds: values.providerIds,
          startDate: startDate?.toDate().toISOString(),
          remittanceDate: values.remittanceDate?.toDate().toISOString(),
          invoiceReceivedDueDate: values.invoiceReceivedDueDate
            ?.toDate()
            .toISOString(),
        });
      },
    });
  };

  const onUpdateIgnoredToppingsButtonClick = () => {
    modal.confirm({
      icon: null,
      title: '編輯要忽略的加項',
      content: (
        <IgnoredToppingsFormComponent
          form={toppingsForm}
          data={props.ignoredToppings}
        />
      ),
      onOk: async () => {
        const values = await toppingsForm.getFieldsValue();

        return props.updateIgnoredToppings({ toppings: values.toppings });
      },
      okText: '確定',
      cancelText: '取消',
    });
  };

  const onCancelButtonClick = (statement: Props['statements'][number]) => {
    const { id } = statement;

    modal.confirm({
      title: '作廢對帳單',
      icon: null,
      okText: '作廢',
      cancelText: '取消',
      content: (
        <CancelStatementFormComponent form={cancelForm} statement={statement} />
      ),
      onOk: () => {
        return props.cancelStatement({
          id,
          note: cancelForm.getFieldValue('note'),
        });
      },
    });
  };

  const onResolveButtonClick = (statement: Props['statements'][number]) => {
    modal.confirm({
      title: '轉為待匯款',
      icon: null,
      okText: '確定',
      cancelText: '取消',
      content: (
        <CancelStatementFormComponent form={cancelForm} statement={statement} />
      ),
      onOk: () => {
        return props.resolveStatement({
          id: statement.id,
          note: cancelForm.getFieldValue('note'),
        });
      },
    });
  };

  const columns: ColumnType<Props['statements'][number]>[] = [
    {
      align: 'center',
      dataIndex: 'states',
      filters: STATEMENT_STATE_FILTERS,
      title: '狀態',
      width: 100,
      render: (v, record) => {
        return (
          <span
            className={`${
              record.state === STATEMENT_STATE.CANCELLED ? 'text-red-500' : ''
            } ${
              record.state === STATEMENT_STATE.PENDING_PAYMENT
                ? 'text-green-500'
                : ''
            } ${record.state === STATEMENT_STATE.TBS ? 'text-orange-500' : ''}
            `}
          >
            {STATEMENT_STATE_LABEL[record.state]}
          </span>
        );
      },
    },
    {
      title: '狀態變更紀錄',
      width: 300,
      dataIndex: 'stateLog',
      render: (v, record) => {
        return (
          <div>
            {map(record.stateLog, (log, index) => (
              <div key={index}>
                {new Date(log.time).toLocaleString()} -{' '}
                {STATEMENT_STATE_LABEL[log.state]}
                {log.note && `(${log.note})`}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      title: '建立時間',
      width: 180,
      render: (record) => new Date(record.createdAt).toLocaleString(),
    },
    {
      dataIndex: 'timeRange',
      title: '對帳單日期',
      width: 180,
      filterMultiple: false,
      // @ts-ignore
      filters: map(props.timeFilters, (timeFilter) => ({
        text: `${new Date(timeFilter[0]).toLocaleDateString()} ~ ${new Date(
          timeFilter[1]
        ).toLocaleDateString()}`,
        value: timeFilter,
      })),
      render: (_v, record) =>
        ` ${new Date(record.startTime).toLocaleDateString()} ~ ${new Date(
          record.endTime
        ).toLocaleDateString()} `,
    },
    {
      dataIndex: 'providerIds',
      filterSearch: true,
      filters: props.providerFilters,
      title: '供應商',
      width: 200,
      render: (v, record) => {
        return `${record.provider.idNumber} - ${record.provider.name}`;
      },
    },
    {
      align: 'center',
      dataIndex: 'amount',
      title: '金額',
      width: 100,
      render: (amount) =>
        amount.toLocaleString('zh-TW', {
          style: 'currency',
          currency: 'TWD',
          minimumFractionDigits: 0,
        }),
    },
    {
      align: 'center',
      title: '營業額',
      width: 100,
      render: (v, record) => {
        let amount = record.itemAmount + record.toppingAmount;
        return amount.toLocaleString('zh-TW', {
          style: 'currency',
          currency: 'TWD',
          minimumFractionDigits: 0,
        });
      },
    },
    {
      align: 'center',
      title: '實付金額',
      width: 100,
      render: (v, record) => {
        let amount =
          record.itemAmount +
          record.toppingAmount -
          record.refundAmount -
          record.commissionAmount -
          record.fineAmount;
        return amount.toLocaleString('zh-TW', {
          style: 'currency',
          currency: 'TWD',
          minimumFractionDigits: 0,
        });
      },
    },
    {
      title: '對帳單連結',
      width: 100,
      render(record) {
        return (
          <div className="flex flex-col">
            <a href={record.statementUrl} target="_blank" rel="noreferrer">
              對帳單
            </a>
            <a href={record.detailUrl} target="_blank" rel="noreferrer">
              對帳單明細
            </a>
          </div>
        );
      },
    },
    {
      dataIndex: 'note',
      title: '備註',
    },
    {
      width: 50,
      align: 'center',
      title: '操作',
      render: (record) => {
        return (
          <Dropdown
            menu={{
              items: [
                {
                  key: 'edit',
                  label: '編輯',
                  icon: <EditOutlined />,
                  onClick: () => {
                    onUpdateButtonClick(record);
                  },
                },
                {
                  key: 'resolve',
                  label: '轉為待匯款',
                  disabled: record.state !== STATEMENT_STATE.TBS,
                  icon: <TransactionOutlined />,
                  onClick: () => {
                    onResolveButtonClick(record);
                  },
                },
                {
                  key: 'divider',
                  type: 'divider',
                },
                {
                  key: 'cancel',
                  label: '作廢',
                  icon: <DeleteOutlined />,
                  disabled: includes(
                    [
                      STATEMENT_STATE.CANCELLED,
                      STATEMENT_STATE.PAID,
                      STATEMENT_STATE.TBD,
                    ],
                    record.state
                  ),
                  onClick: () => {
                    onCancelButtonClick(record);
                  },
                },
              ],
            }}
          >
            <Button icon={<MoreOutlined />} shape="circle" />
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      {contentHolder}
      <div className="text-2xl font-bold">對帳單管理</div>
      <Table
        columns={columns}
        dataSource={props.statements}
        pagination={props.pagination}
        rowKey="id"
        size="small"
        scroll={{ x: 'max-content' }}
        loading={props.isLoading}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedIds,
          onChange: (selectedRowKeys) => {
            setSelectedIds(selectedRowKeys);
          },
        }}
        onChange={(pagination, filters) => {
          props.setFilter(filters);
        }}
        title={() => (
          <div className="flex items-center gap-2">
            <Button type="primary" onClick={onExportButtonClick}>
              匯出對帳單
            </Button>
            <Button type="primary" onClick={onUpdateIgnoredToppingsButtonClick}>
              編輯要忽略的加項
            </Button>
            {selectedIds.length > 0 && (
              <Button
                type="primary"
                onClick={onSendStatementConfirmNotificationButtonClick}
              >
                發送對帳單確認通知
              </Button>
            )}
            {selectedIds.length > 0 && (
              <Button type="primary" onClick={onPayButtonClick}>
                變更對帳單狀態為已付款
              </Button>
            )}
            {selectedIds.length > 0 && (
              <Button
                type="primary"
                onClick={onDownloadStatementDetailButtonClick}
              >
                下載對帳單款明細
              </Button>
            )}
          </div>
        )}
      />
    </>
  );
}
