import {
  useCreateProvider,
  useDeleteProvider,
  useProviderPublishMessage,
  useProviders,
  useScheduleMeals,
  useUpdateProvider,
} from '../utils/api.hook';
import { ProvidersComponent } from './providers.component';
import { usePagination } from '../shared/usePagintation';
import { useState } from 'react';
import { FilterValue } from 'antd/es/table/interface';
import dayjs from 'dayjs';

export function ProvidersContainer() {
  const [keyword, setKeyword] = useState<string>('');
  const [filters, setFilters] = useState<Record<string, FilterValue>>({});
  const {
    data: { providers, total } = {},
    isLoading,
    isFetching,
  } = useProviders({
    keyword,
    ...filters,
  });
  const { mutateAsync: createProvider, isLoading: isCreatingProvider } =
    useCreateProvider();
  const { mutateAsync: deleteProvider, isLoading: isDeletingProvider } =
    useDeleteProvider();
  const { mutateAsync: updateProvider, isLoading: isUpdatingProvider } =
    useUpdateProvider();
  const { data: { deliveryMeals: todayDeliveryMeals = [] } = {} } =
    useScheduleMeals({
      startTime: dayjs().startOf('day').valueOf(),
      endTime: dayjs().endOf('day').valueOf(),
    });
  const { mutateAsync: sendProviderPublishMessage } =
    useProviderPublishMessage();
  const pagination = usePagination({
    defaultPageSize: 15,
  });

  return (
    <ProvidersComponent
      createProvider={createProvider}
      data={providers}
      deleteProvider={deleteProvider}
      setKeyword={setKeyword}
      updateProvider={updateProvider}
      todayDeliveryMeals={todayDeliveryMeals}
      sendProviderPublishMessage={sendProviderPublishMessage}
      setFilters={setFilters}
      isLoading={
        isLoading ||
        isFetching ||
        isCreatingProvider ||
        isDeletingProvider ||
        isUpdatingProvider
      }
      pagination={{
        ...pagination,
        total,
      }}
    />
  );
}
