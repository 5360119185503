import { first, map } from 'lodash';
import {
  useCancelStatement,
  useDownloadStatementsDetail,
  useExportProviderStatements,
  useIgnoredToppings,
  usePayStatements,
  useResolveStatement,
  useSendConfirmNotification,
  useStatements,
  useUpdateIgnoredToppings,
  useUpdateStatement,
} from '../../utils/api.hook';
import { usePagination } from '../../shared/usePagintation';
import { StatementsComponent } from './statements.component';
import { Key, useState } from 'react';

export function StatementsContainer() {
  const [filter, setFilter] = useState<Record<string, any>>({});
  const pagination = usePagination();
  const [selectedStatementsIds, setSelectStatementIds] = useState<Key[]>([]);
  const { mutateAsync: exportProviderStatements, isLoading } =
    useExportProviderStatements();
  const { data: { toppings } = {}, isLoading: isToppingsLoading } =
    useIgnoredToppings();
  const { mutateAsync: updateStatement, isLoading: isUpdateStatementLoading } =
    useUpdateStatement();
  const { mutateAsync: payStatements, isLoading: isPayStatementsLoading } =
    usePayStatements();
  const {
    data: { statements, total, providerFilters, timeFilters } = {},
    isLoading: isStatementsLoading,
    isFetching,
  } = useStatements({
    ...filter,
    timeRange: first(filter?.timeRange),
    skip: pagination.skip,
    take: pagination.take,
  });
  const { mutateAsync: cancelStatement, isLoading: isCancelStatementLoading } =
    useCancelStatement();
  const {
    mutateAsync: sendStatementConfirmNotification,
    isLoading: isSendStatementConfirmNotificationLoading,
  } = useSendConfirmNotification();
  const {
    isLoading: isDownloadStatementsDetailLoading,
    isFetching: isDownloadStatementsDetailFetching,
  } = useDownloadStatementsDetail(selectedStatementsIds);
  const {
    isLoading: isFetchingResolveStatement,
    mutateAsync: resolveStatement,
  } = useResolveStatement();

  const {
    mutateAsync: updateIgnoredToppings,
    isLoading: isUpdateIgnoredToppingsLoading,
  } = useUpdateIgnoredToppings();

  const downloadStatementsDetail = (ids: Key[]) => {
    setSelectStatementIds(ids);
    setTimeout(() => {
      setSelectStatementIds([]);
    }, 1);
  };

  return (
    <StatementsComponent
      cancelStatement={cancelStatement}
      exportProviderStatements={exportProviderStatements}
      ignoredToppings={toppings || []}
      payStatements={payStatements}
      sendStatementConfirmNotification={sendStatementConfirmNotification}
      setFilter={setFilter}
      statements={statements || []}
      timeFilters={timeFilters || []}
      updateIgnoredToppings={updateIgnoredToppings}
      updateStatement={updateStatement}
      downloadStatementDetail={downloadStatementsDetail}
      resolveStatement={resolveStatement}
      isLoading={
        isLoading ||
        isToppingsLoading ||
        isUpdateIgnoredToppingsLoading ||
        isStatementsLoading ||
        isFetching ||
        isCancelStatementLoading ||
        isUpdateStatementLoading ||
        isSendStatementConfirmNotificationLoading ||
        isPayStatementsLoading ||
        isDownloadStatementsDetailLoading ||
        isDownloadStatementsDetailFetching ||
        isFetchingResolveStatement
      }
      providerFilters={
        map(providerFilters, (provider) => ({
          value: provider.id,
          text: `${provider.idNumber} - ${provider.name}`,
        })) || []
      }
      pagination={{
        ...pagination,
        total,
      }}
    />
  );
}
