import { Form } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import TextArea from 'antd/es/input/TextArea';
import { useEffect } from 'react';

import { ProviderSelectorContainer } from '../shared/provider-selector/provider-selector.containers';

export function PublishMessageForm({
  form,
  data,
  needProvider = false,
}: {
  form: FormInstance;
  needProvider?: boolean;
  data?: {
    providerIds: string[];
    message: string;
  };
}) {
  useEffect(() => {
    form.resetFields();
  }, [form]);

  return (
    <Form form={form} initialValues={data} autoComplete="off">
      {needProvider && (
        <ProviderSelectorContainer
          multiple
          isRequired
          label={
            <div className="flex items-center gap-2">
              <span>供應商</span>
            </div>
          }
        />
      )}

      <FormItem
        name="message"
        label="訊息內容"
        rules={[
          {
            required: true,
            message: '請輸入訊息內容',
          },
        ]}
      >
        <TextArea rows={5} />
      </FormItem>
    </Form>
  );
}
