import {
  FileTextOutlined,
  MoneyCollectOutlined,
  ProductOutlined,
  ScheduleOutlined,
  SettingOutlined,
  ShopOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { LogoutOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Layout, Menu, Space } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import axios from 'axios';
import { first } from 'lodash';
import { MenuProps } from 'rc-menu';
import React, { useLayoutEffect, useState } from 'react';
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { ProductRefundContainer } from './accounting/product-refund/product-refund.container';
import { ProviderEventsContainer } from './accounting/provider-events/provider-events.container';
import { StatementsContainer } from './accounting/statements/statements.container';
import './App.css';
import { DriversContainer } from './drivers/drivers.container';
import { DriversSalaryContainer } from './drivers/salary/drivers-salary.container';
import { LocationsContainer } from './locations/locations.container';
import { LocationsTagsContainer } from './locations/tags/locations-tags.container';
import { LoginContainer } from './login/login.container';
import { OrdersContainer } from './orders/orders.container';
import { OrderStatisticsContainer } from './orders/statistics/order-statistics.container';
import { ProductsContainer } from './products/products.container';
import { ProvidersContainer } from './providers/providers.container';
import { ProvidersTagsContainer } from './providers/tags/providers-tags.container';
import { ScheduleDriversContainer } from './schedules/drivers/schedule-drivers.container';
import { ScheduleMealsContainer } from './schedules/meals/schedule-meals.container';
import { ScheduleOverviewContainer } from './schedules/overview/schedule-overview.container';
import { ScheduleProviderOverviewContainer } from './schedules/providerOverview/schedule-provider-overview.container';
import { PermissionsContainer } from './settings/permissions/permissions.container';
import { RolesContainer } from './settings/roles/roles-container';
import { UsersContainer } from './settings/users/users.container';
import { PrivateRoute } from './shared/private-route';
import { PublicRoute } from './shared/public-route.container';
import { useAuthStore } from './utils/stores/useAuthStore';
import { ReceivablesContainer } from './accounting/receivables/receivables.container';

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label: children ? (
      label
    ) : (
      <Link to={key.toString()} key={key}>
        {label}
      </Link>
    ),
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('供應商管理', '/providers/', <UserOutlined />, [
    getItem('供應商列表', '/providers'),
    getItem('供應商標籤', '/providers/tags'),
  ]),
  getItem('商品管理', '/products', <ProductOutlined />),
  getItem('訂單管理', '/orders/', <FileTextOutlined />, [
    getItem('訂單列表', '/orders'),
    getItem('訂單統計', '/orders/statistics'),
  ]),
  getItem('帳務管理', '/accounting', <MoneyCollectOutlined />, [
    getItem('對帳單管理', '/accounting/statements'),
    getItem('商品退款管理', '/accounting/product-refund'),
    getItem('供應商事件管理', '/accounting/provider-events'),
    getItem('收款管理', '/accounting/receivables'),
  ]),
  getItem('大樓管理', '/locations/', <ShopOutlined />, [
    getItem('大樓列表', '/locations'),
    getItem('大樓標籤', '/locations/tags'),
  ]),
  getItem('班表管理', '/schedules', <ScheduleOutlined />, [
    getItem('餐點配置', '/schedules/meals'),
    getItem('司機配置', '/schedules/drivers'),
    getItem('班表總覽', '/schedules/overview'),
    getItem('供應商配餐總覽', '/schedules/providerOverview'),
  ]),
  getItem('司機管理', '/drivers', <ProductOutlined />, [
    getItem('司機列表', '/drivers'),
    getItem('司機薪資', '/drivers/salary'),
  ]),
  getItem('系統設定', '/settings', <SettingOutlined />, [
    getItem('用戶管理', '/settings/users'),
    getItem('角色管理', '/settings/roles'),
    getItem('權限列表', '/settings/permissions'),
  ]),
];

const App: React.FC = () => {
  const location = useLocation();
  const auth = useAuthStore();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [collapsed, setCollapsed] = useState(true);

  useLayoutEffect(() => {
    if (token && !auth.token) {
      auth.login(token);
    }
  }, [token, auth.login, auth, auth.token]);

  useLayoutEffect(() => {
    if (auth.token) {
      axios.interceptors.request.clear();
      axios.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${auth.token}`;

        return config;
      });
    }
  }, [auth.token]);

  useLayoutEffect(() => {
    axios.interceptors.response.clear();

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          auth.logout();
        }

        throw error;
      }
    );
  }, [auth, auth.logout]);

  return (
    <Layout className="min-h-[100vh] max-h-[100vh] h-[100vh]">
      {auth.token && (
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Menu
            theme="dark"
            mode="inline"
            items={items}
            selectedKeys={[location.pathname]}
          />
        </Sider>
      )}
      <Layout>
        {auth.token && (
          <Header className="flex items-center justify-between px-4">
            <span className="text-2xl font-bold text-gray-200">主控台</span>
            <Dropdown
              menu={{
                items: [
                  {
                    key: 'logout',
                    icon: <LogoutOutlined />,
                    label: '登出',
                    onClick: auth.logout,
                  },
                ],
              }}
            >
              <Space>
                <Avatar
                  size="large"
                  src={auth.user?.photoUrl}
                  className="bg-gray-200 text-[#001529]"
                >
                  {first(auth.user?.displayName?.split(''))}
                </Avatar>
                <span className="text-gray-200">{auth.user?.displayName}</span>
              </Space>
            </Dropdown>
          </Header>
        )}
        <Content className="p-4 overflow-auto">
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path="orders">
                <Route index key="index" element={<OrdersContainer />} />
                <Route
                  key="statistics"
                  path="statistics"
                  element={<OrderStatisticsContainer />}
                />
                <Route
                  path="*"
                  key="default"
                  element={<Navigate to="" replace />}
                />
              </Route>
              <Route
                path="locations"
                children={[
                  <Route index key="index" element={<LocationsContainer />} />,
                  <Route
                    key="tags"
                    path="tags"
                    element={<LocationsTagsContainer />}
                  />,
                  <Route
                    path="*"
                    key="default"
                    element={<Navigate to="" replace />}
                  />,
                ]}
              />
              <Route path="products" element={<ProductsContainer />} />
              <Route
                path="accounting"
                children={[
                  <Route
                    index
                    key="index"
                    element={<Navigate to="statements" replace />}
                  />,
                  <Route
                    path="statements"
                    key="statements"
                    element={<StatementsContainer />}
                  />,
                  <Route
                    path="product-refund"
                    key="product-refund"
                    element={<ProductRefundContainer />}
                  />,
                  <Route
                    path="provider-events"
                    key="provider-events"
                    element={<ProviderEventsContainer />}
                  />,
                  <Route
                    path="receivables"
                    key="receivables"
                    element={<ReceivablesContainer />}
                  />,
                  <Route
                    path="*"
                    key="default"
                    element={<Navigate to="" replace />}
                  />,
                ]}
              />
              <Route
                path="providers"
                children={[
                  <Route index key="index" element={<ProvidersContainer />} />,
                  <Route
                    key="tags"
                    path="tags"
                    element={<ProvidersTagsContainer />}
                  />,
                  <Route
                    path="*"
                    key="default"
                    element={<Navigate to="" replace />}
                  />,
                ]}
              />
              <Route
                path="schedules"
                children={[
                  <Route
                    index
                    key="index"
                    element={<Navigate to="meals" replace />}
                  />,
                  <Route
                    key="meals"
                    path="meals"
                    element={<ScheduleMealsContainer />}
                  />,
                  <Route
                    key="drivers"
                    path="drivers"
                    element={<ScheduleDriversContainer />}
                  />,
                  <Route
                    key="overview"
                    path="overview"
                    element={<ScheduleOverviewContainer />}
                  />,
                  <Route
                    key="providerOverview"
                    path="providerOverview"
                    element={<ScheduleProviderOverviewContainer />}
                  />,
                  <Route
                    path="*"
                    key="default"
                    element={<Navigate to="" replace />}
                  />,
                ]}
              />
              <Route
                path="drivers"
                children={[
                  <Route index key="index" element={<DriversContainer />} />,
                  <Route
                    key="salary"
                    path="salary"
                    element={<DriversSalaryContainer />}
                  />,
                  <Route
                    path="*"
                    key="default"
                    element={<Navigate to="" replace />}
                  />,
                ]}
              />
              <Route
                path="settings"
                children={[
                  <Route
                    index
                    key="index"
                    element={<Navigate to="users" replace />}
                  />,
                  <Route
                    key="users"
                    path="users"
                    element={<UsersContainer />}
                  />,
                  <Route
                    key="roles"
                    path="roles"
                    element={<RolesContainer />}
                  />,
                  <Route
                    key="permissions"
                    path="permissions"
                    element={<PermissionsContainer />}
                  />,
                  <Route
                    path="*"
                    key="default"
                    element={<Navigate to="" replace />}
                  />,
                ]}
              />
              <Route path="*" element={<Navigate to="providers" replace />} />
            </Route>
            <Route element={<PublicRoute />}>
              <Route path="login" element={<LoginContainer />} />
            </Route>
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
